import './App.css';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone'


const Image = ({ file, settings, removeFile }) => {
  const [imageSettings] = useState(settings);
  const [dataUrl, setDataUrl] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [id, setId] = useState(false);

  useEffect(() => {
    const reader = new FileReader()

    reader.onload = () => {
      // Do whatever you want with the file contents
      const fileUrl = reader.result
      setDataUrl(fileUrl)
    }

    reader.readAsDataURL(file);
  }, []);

  useEffect(() => {
    const upload = async () => {
      let formData = new FormData()
      formData.append('file', file);


      let request = new XMLHttpRequest();
      request.open('POST', '/upload');

      request.upload.addEventListener('progress', function(e) {
        let percent_completed = (e.loaded / e.total)*100;
        setUploadProgress(percent_completed);
      });

      request.addEventListener('load', function(e) {
        setUploadProgress(100);
        const data = JSON.parse(request.response);
        setId(data.id);
      });

      request.send(formData)

    }

    upload();
  }, [])

  return (
    <li style={{listStyle: 'none', borderBottom: '2px dotted black', padding: '1em 0', margin: '1em 0', boxSizing: 'border-box'}}>
      <progress max={100} value={uploadProgress} style={{width: '100%'}} />
      <p>{file.path}</p>
      <div style={{display: 'flex', alignItems: 'center', gap: 20}}>
        <div style={{width: 100, height: 100, display: 'flex',}}>{dataUrl ? <img style={{width: '100%', height: '100%', objectFit: 'contain'}} src={dataUrl} /> : ''}</div>
        {id ? <a target="_blank" onClick={removeFile} href={`/download/${id}?width=${imageSettings.width}&height=${imageSettings.height}`}>
          Download (Breite: {imageSettings.width} / Höhe: {imageSettings.height})
        </a> : ''}
      </div>
    </li>
  );
}

function App() {
  const [files, setFiles] = useState({});
  const [settings, setSettings] = useState({
    format: 'jpg',
    width: parseInt(localStorage.getItem('width') || '1000'),
    height: parseInt(localStorage.getItem('height') || '1000'),
  });

  useEffect(() => {
    localStorage.setItem('width', settings.width);
    localStorage.setItem('height', settings.height);
  }, [settings.width, settings.height])

  const onDrop = useCallback(acceptedFiles => {
    setFiles((files) => {
      for (const file of acceptedFiles) {
        files[file.path] = file;
      }

      return files;
    })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': '.png',
      'image/jpg': '.jpg',
      'image/jpeg': '.jpg'
    }
  })

  return (
    <div className="App">
      <h1>Bilder für Web optimieren</h1>
      <ul style={{padding: 0}}>
        {Object.keys(files).map(fileName => <Image key={fileName} file={files[fileName]} settings={settings} removeFile={() => {
          setFiles((files) => {
            const newFiles = {...files};
            delete(newFiles[fileName]);
            return newFiles
          })
        }} />)}
      </ul>

      <div style={{minHeight: 100, border: '1px solid black', display: 'flex', justifyContent: 'center', alignItems: 'center'}} {...getRootProps()}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Bilder hier ablegen</p> :
            <p>Bitte Bilder hier ablegen oder klicken</p>
        }
      </div>

      <div>
        <h2>Einstellugen</h2>
        <table>
          <tbody style={{textAlign: 'left'}}>
            <tr>
              <th>Format</th>
              <td>{settings.format}</td>
            </tr>
            <tr>
              <th>Breite</th>
              <td><input type='number' value={settings.width} onChange={e => setSettings((settings) => ({...settings, width: parseInt(e.target.value)}))} /></td>
            </tr>
            <tr>
              <th>Höhe</th>
              <td><input type='number' value={settings.height} onChange={e => setSettings((settings) => ({...settings, height: parseInt(e.target.value)}))} /></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default App;
